<template>
  <!--begin::Mixed Widget 5-->
  <div class="card card-custom bg-radial-gradient-success gutter-b card-stretch">
    <!--begin::Header-->

    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-0">
      <!--begin::Chart-->


      <!--begin::Stats-->
      <div class="card-spacer bg-white card-rounded flex-grow-1">
        <!--begin::Row-->


        <div class="row m-0">
          <div class="col px-8 py-6 mr-8">
            <div class="font-size-sm text-muted font-weight-bold">
              Campaign count
            </div>
            <div class="font-size-h4 font-weight-bolder">{{campaignsCount }}</div>
          </div>

        </div>



        <div class="row m-0">
          <div class="col px-8 py-6 mr-8">
            <div class="font-size-sm text-muted font-weight-bold">
              Clicks {{statsDescriptionByType}}
            </div>
            <div class="font-size-h4 font-weight-bolder">{{total.click }}</div>
          </div>

        </div>
        <!--end::Row-->
        <!--begin::Row-->


        <!--end::Row-->

        <!--begin::Row-->

        <div class="row m-0">

          <div class="col px-8 py-6">
            <div class="font-size-sm text-muted font-weight-bold">
              Click budget left
            </div>
            <div class="font-size-h4 font-weight-bolder">{{(userdata.clicks_paid || 0) - (userdata.clicks_used || 0)}}</div>
          </div>
        </div>
        <!--end::Row-->


      </div>
      <!--end::Stats-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Mixed Widget 5-->
</template>

<script>
import { mapGetters, mapState } from "vuex";
import moment from 'moment';

import ApiService from "@/core/services/api.service";

const getCategories = (count) => {
  return new Array(count).fill(0).map((it, index) => {
    const date = moment().subtract(index, "days").format("YYYY-MM-DD");

    return date;
  }).reverse()
}

export default {
  name: "DashboardStat",
  props: {
    statTab: String
  },
  data() {
    return {
      chartOptions: {},
      series: [
        {
          name: "Click Total",
          data: []
        },
        {
          name: "Click Valid",
          data: []
        }
      ],
      total: {},
      categories: []
    };
  },
  computed: {
    ...mapGetters(["layoutConfig", "userdata"]),
    ...mapState({
      campaignsCount: state => state.campaign.list.length
    }),
    statsDescriptionByType() {
      let description;

      switch (this.statTab) {
        case "today":
          description = 'Today';
          break;
        case "yesterday":
          description = 'Yesterday';
          break;
        case "past7days":
          description = 'Last 7 days';
          break;
        case "past30days":
          description = 'Last 30 days';
          break;
        case "all":
        default:
          description = 'All time';
      }

      return description
    }
  },
  async created() {
    const statRows = await this.getStats();

    this.categories = getCategories(7)
    const categories = [...this.categories];

    const structure = {
      click: 0,
      click_filtered: 0,
      click_not_filtered: 0,
      cloak_rate: 0
    }

    const dataObj = Object.assign({
      total: structure
    }, ...categories.map(it => ({
      [it]: structure
    })))

    if (statRows && statRows.length) {
      statRows.forEach(row => {
        if (!row.DAY) {
          dataObj.total = { ...dataObj.total, ...row };
          return;
        }

        const date = moment(row.DAY.value).format("YYYY-MM-DD");

        if (dataObj[date]) {
          dataObj[date] = { ...dataObj[date], ...row }
        }
      })
    }

    const dates = [...categories].sort((a, b) => new Date(a) - new Date(b));

    const clickTotalSeries = dates.map(date => {
      return dataObj[date].click;
    });
    const clickValidSeries = dates.map(date => {
      return dataObj[date].click_not_filtered;
    });

    this.$set(this.series, 0, {
      ...this.series[0],
      data: clickTotalSeries
    })

    this.$set(this.series, 1, {
      ...this.series[1],
      data: clickValidSeries
    })

    this.total = dataObj.total;
  },
  async mounted() {
    // reference; kt_mixed_widget_1_chart
    this.chartOptions = {
      chart: {
        type: "bar",
        height: 200,
        toolbar: {
          show: false
        },
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["30%"],
          endingShape: "rounded"
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 10,
        // colors: ["transparent"]
      },
      xaxis: {
        categories: this.categories,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      yaxis: {
        min: 0,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      fill: {
        opacity: 1
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: this.layoutConfig("font-family")
        },
        y: {
          formatter: function(val) {
            return val + " clicks";
          }
        }
      },
      colors: [
        this.layoutConfig("colors.theme.light.success"),
        this.layoutConfig("colors.theme.base.white")
      ],
      grid: {
        borderColor: this.layoutConfig("colors.gray.gray-200"),
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: false
          }
        }
      }
    };
  },
  methods: {
    async getStats() {
      const data = await ApiService.query(`/stats/type?type=${this.statTab}`)
        .then(res => res.data)

      return data;
    },
  }
};
</script>
