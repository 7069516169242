<template>
  <v-app>
    <div class="row mt-0" v-show="loading">
      <div class="atom-spinner-wrapper">
        <atom-spinner
          :animation-duration="1000"
          :size="100"
          color="#1BC5BD"
        />
      </div>
    </div>
    <div class="row mt-0" v-show="!loading">
      <div class="col-xl-12 pt-0">
        <DashboardTable :openStatIframe="openStatIframe" :loading="loading" @set-loading="setLoading($event)" />
      </div>
    </div>

    <div v-if="!loading && campaigns.length" >
      <div class="d-flex align-items-center mt-lg-12 mt-5">
        <div class="tabs outlined">
          <button
            class="tab"
            :class="{ active: statTab === 'today' }"
            @click="setActiveStatTab('today')"
          >
            Today
          </button>
          <button
            class="tab"
            :class="{ active: statTab === 'yesterday' }"
            @click="setActiveStatTab('yesterday')"
          >
            Yesterday
          </button>
          <button
            class="tab"
            :class="{ active: statTab === 'past7days' }"
            @click="setActiveStatTab('past7days')"
          >
            Last 7 days
          </button>
          <button
            class="tab"
            :class="{ active: statTab === 'past30days' }"
            @click="setActiveStatTab('past30days')"
          >
            Last month
          </button>
          <button
            class="tab"
            :class="{ active: statTab === 'thisyear' }"
            @click="setActiveStatTab('thisyear')"
          >
            All
          </button>
        </div>

        <!--
        <span class="switch switch-sm switch-outline whitelist-switch ml-auto">
          <label
            ><input
              type="checkbox"
              checked="checked"
              name="select"
              v-model="cloak"
              @change="changecloak"
            /><span></span
          ></label>
          <div class="font-size-sm font-weight-bold ml-1 mt-0">cloak</div>
        </span>
        -->
      </div>

      <div class="row mt-0 mt-lg-12" v-if="showLoader">
        <div style="margin: auto">
          <hollow-dots-spinner
            :animation-duration="1000"
            :dot-size="12"
            :dots-num="3"
            color="#36c8c0"
          />
        </div>
      </div>


      <div v-if="dashboard == 1">

        <div class="row mt-0 mt-lg-12" v-if="showIframe">
          <!--
          <div class="col-xl-2">
            <DashboardStat :statTab="statTab" :key="statTab" />
          </div>
        -->

          <div class="col-xl-7">
            <div class="card card-custom card-stretch gutter-b">
              <div class="card border-0 py-5">
                <div
                  style="
                    margin: auto;
                    color: #7f839a;
                    font-size: 14px;
                    font-weight: 400;
                    padding-bottom: 20px;
                  "
                >
                  Campaign <b>{{ campaign }}</b> Stats
                </div>
                <metabaseQ2 :url="iframe2"></metabaseQ2>
              </div>
            </div>
          </div>
          <div class="col-xl-5">
            <div class="card card-custom card-stretch gutter-b">
              <div class="card border-0 py-5">
                <div
                  style="
                    margin: auto;
                    color: #7f839a;
                    font-size: 14px;
                    font-weight: 400;
                    padding-bottom: 20px;
                  "
                >
                  Clicks by day / hours
                </div>
                <metabaseQ2 :url="iframe3"></metabaseQ2>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row mt-0 mt-lg-12"
          v-if="showIframe && dashboard == 2"
          style="margin-top: 0px !important"
        >
          <div class="col-xl-2">
            <div class="card card-custom card-stretch gutter-b">
              <div
                style="
                  margin: auto;
                  color: #7f839a;
                  font-size: 14px;
                  font-weight: 400;
                  padding-bottom: 20px;
                "
              >
                Device Stats
              </div>
              <metabaseQ3 :url="iframe4"></metabaseQ3>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="card card-custom card-stretch gutter-b">
              <div
                style="
                  margin: auto;
                  color: #7f839a;
                  font-size: 14px;
                  font-weight: 400;
                  padding-bottom: 20px;
                "
              >
                Country Stats
              </div>
              <metabaseQ3 :url="iframe5"></metabaseQ3>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="card card-custom card-stretch gutter-b">
              <MetabaseM :url="iframe6"></MetabaseM>
            </div>
          </div>
        </div>

        <div class="row mt-3" v-if="showIframe" style="margin-top: 0px !important">
          <div class="col-12" style="margin: 15px 0 0 0">
            <div class="card card-custom card-stretch gutter-b">
              <div class="card border-0 py-5">
                <metabaseQ :url="iframe"></metabaseQ>
              </div>
            </div>
          </div>
        </div>


      </div>

      <div v-if="dashboard == 2">

        <div class="row mt-0 mt-lg-12" v-if="showGlobalStatsButton && !showLoader">
          <div
            style="margin: auto; color: #7f839a; font-size: 14px; font-weight: 400"
          >
            <button
              style="margin:left:10px; color: #7f839a;font-size: 14px;font-weight: 400;"
              @click="openStatIframe('%')"
            >
              <inline-svg src="media/svg/icons/Navigation/Angle-double-left.svg" />
              Back to all stats
            </button>
          </div>
        </div>

        <div class="row mt-0 mt-lg-12" v-if="showIframe">

          <div class="col-xl-6">
            <div class="card card-custom card-stretch gutter-b">
              <div class="card border-0 py-5">
                <div
                  style="
                    margin: auto;
                    color: #7f839a;
                    font-size: 14px;
                    font-weight: 400;
                    padding-bottom: 20px;
                  "
                >
                  Campaign <b>{{ campaign }}</b> Stats
                </div>
                <metabaseQ2 :url="iframe2"></metabaseQ2>
              </div>
            </div>
          </div>

          <div class="col-xl-6">
            <div class="card card-custom card-stretch gutter-b">
              <div class="card border-0 py-5">
                <div
                  style="
                    margin: auto;
                    color: #7f839a;
                    font-size: 14px;
                    font-weight: 400;
                    padding-bottom: 20px;
                  "
                >
                  Clicks by hour
                </div>
                <metabaseQ2 :url="iframe3"></metabaseQ2>
              </div>
            </div>
          </div>

        </div>

        <div
          class="row mt-0 mt-lg-12"
          v-if="showIframe"
          style="margin-top: 0px !important"
        >

          <div class="col-xl-2">
            <div class="card card-custom card-stretch gutter-b">
              <div
                style="
                  margin: auto;
                  color: #7f839a;
                  font-size: 14px;
                  font-weight: 400;
                  padding-bottom: 20px;
                "
              >
                Device Stats
              </div>
              <metabaseQ3 :url="iframe4"></metabaseQ3>
            </div>
          </div>

          <div class="col-xl-4">
            <div class="card card-custom card-stretch gutter-b">
              <div
                style="
                  margin: auto;
                  color: #7f839a;
                  font-size: 14px;
                  font-weight: 400;
                  padding-bottom: 20px;
                "
              >
                Country Stats
              </div>
              <metabaseQ3 :url="iframe5"></metabaseQ3>
            </div>
          </div>

          <div class="col-xl-6">
            <div class="card card-custom card-stretch gutter-b">
              <MetabaseM :url="iframe6"></MetabaseM>
            </div>
          </div>
        </div>

        <div class="row mt-3" v-if="showIframe" style="margin-top: 0px !important">
          <div class="col-12" style="margin: 15px 0 0 0">
            <div class="card card-custom card-stretch gutter-b">
              <div class="card border-0 py-5">
                <metabaseQ :url="iframe"></metabaseQ>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import moment from 'moment';
import { HollowDotsSpinner, AtomSpinner } from "epic-spinners";
import DashboardTable from "@/view/components/Dashboard/DashboardTable";
import DashboardStat from "@/view/components/Dashboard/DashboardStat";

import MetabaseQ from "@/view/content/metabaseQ.vue";
import MetabaseQ2 from "@/view/content/metabaseQ2.vue";
import MetabaseQ3 from "@/view/content/metabaseQ3.vue";

import MetabaseM from "@/view/content/metabaseM.vue";

import ApiService from "@/core/services/api.service";

export default {
  name: "Dashboard",
  components: {
    HollowDotsSpinner,
    AtomSpinner,
    DashboardTable,
    //DashboardStat,
    MetabaseQ,
    MetabaseQ2,
    MetabaseQ3,
    MetabaseM,
  },
  data() {
    return {
      message: "Loading...",
      iframe: null,
      iframe2: null,
      iframe3: null,
      iframe4: null,
      iframe5: null,
      iframe6: null,
      showLoader: true,
      showIframe: true,
      showGlobalStatsButton: false,
      dashboard: 1,
      cloak : null,
      campaign: null,
      date: moment().subtract(7, "day").format("YYYY-MM-DD"), //moment().format("YYYY-MM-DD"), //if button click is last 7 days => take the date seven days ago - "2021-07-01"
      statTab: "past7days",
      dateName:"past7days",
      loading: true
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Campaign" }]);
    this.openStatIframe("%"); //open all
    this.showGlobalStatsButton = false;
    let self = this;
    setTimeout(function () {
      self.message =
        "You account is not ready yet. Please wait our team to set you up";
    }, 3000);
  },
  computed: {
    ...mapGetters(["userdata"]),
    ...mapState({
      campaigns: state => state.campaign.list
    }),
  },
  methods: {
    setLoading(value) {
      this.loading = value;
    },
    setActiveStatTab(name) {
      this.statTab = name;
      this.dateName = name;

      if (name === "today") {
        this.date = moment().format("YYYY-MM-DD");
      } else if (name === "yesterday") {
        this.date = moment().subtract(1, "day").format("YYYY-MM-DD");
      } else if (name === "past7days") {
        this.date = moment().subtract(7, "day").format("YYYY-MM-DD");
      } else if (name === "past30days") {
        this.date = moment().subtract(30, "day").format("YYYY-MM-DD");
      } else if (name === "thisyear") {
        this.date = "2020-01-01";
      }

      this.openStatIframe(this.campaign || "%");
    },

    changecloak() {
      //this.openStatIframe(this.campaign || "%");
    },

    async openStatIframe(campaign) {
      this.showIframe = true;
      this.showLoader = false;
      this.showGlobalStatsButton = campaign !== '%';

      if (!this.userdata || !this.userdata.apikey) {
        return
      }

      campaign = campaign ? campaign : "%";

      if (campaign && campaign != "%") {
        this.dashboard = 2;
        this.campaign = campaign;
      } else {
        this.dashboard = 1;
        this.campaign = null;
      }



      let response = null
      let response1 = null
      let response2 = null
      let response3 = null
      let response4 = null
      let response5 = null
      let response6 = null


      // --- traffic table
      if(this.cloak){
        response = await ApiService.get(`metabase`,`?question=43&apikey=${this.userdata.apikey}&campaign=${campaign}&date=${this.date}&cloak=${this.cloak}`);
      } else {
        response = await ApiService.get(`metabase`,`?question=27&apikey=${this.userdata.apikey}&campaign=${campaign}&date=${this.date}`);
      }
      this.iframe = response.data;


      // --- campaign stats
      response2 = await ApiService.get(`metabase`,`?question=38&apikey=${this.userdata.apikey}&campaign=${campaign}&date=${this.date}`);
      this.iframe2 = response2.data;


      // --- click by day or hours
      if(this.statTab == "today" || this.statTab == "yesterday"){
        response3 = await ApiService.get(`metabase`,`?question=35&apikey=${this.userdata.apikey}&campaign=${campaign}&date=${this.date}`);
      } else {
        response3 = await ApiService.get(`metabase`,`?question=44&apikey=${this.userdata.apikey}&campaign=${campaign}&date=${this.date}`);
      }
      this.iframe3 = response3.data;



      // --- DASHBOARD 2

      // --- device // 39 before
      response4 = await ApiService.get(`metabase`,`?question=45&apikey=${this.userdata.apikey}&campaign=${campaign}&date=${this.date}`);
      this.iframe4 = response4.data;

      // --- country stats
      response5 = await ApiService.get(`metabase`,`?question=42&apikey=${this.userdata.apikey}&campaign=${campaign}&date=${this.date}`);
      this.iframe5 = response5.data;

      // --- map
      response6 = await ApiService.get(`metabase`,`?dashboard=7&apikey=${this.userdata.apikey}&campaign=${campaign}&date=${this.dateName}`);
      this.iframe6 = response6.data;



          /*
        response2 = await ApiService.get(
          `metabase`,
          `?question=36&apikey=${this.userdata.apikey}&date=${this.date}&cloak=${this.cloak}`
        );
        this.iframe2 = response2.data;
        */

        // ---


      let self = this;
      setTimeout(function () {
        self.showLoader = false;
        self.showIframe = true;
      }, 3000);
    },


  },
};
</script>

<style scoped>
.atom-spinner-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
}

.tabs {
  display: flex;
  align-items: center;
}

.tabs .tab {
  border-radius: 3rem;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  font-weight: 500;
  margin-right: 3px;
  border: 1px solid transparent;
}

.tabs .tab:hover {
  color: #1bc5bd;
}

.tabs.filled .tab.active {
  background-color: #1bc5bd;
  color: #ffffff;
}

.tabs.outlined .tab.active {
  border: 1px solid #1bc5bd;
  color: #1bc5bd;
}

.switch input:checked ~ span:after {
  background-color: #23c8c0;
}
</style>
