<template>
  <div>
    <div class="card card-custom card-stretch gutter-b" v-if="loading">
      <div class="card-body px-15 py-15 ">
        <div>
          <h3 class="card-title align-items-start flex-column text-center">
            <span class="card-label font-weight-bolder text-muted">
              Loading ...
            </span>
          </h3>
        </div>
      </div>
    </div>
    <div class="card card-custom card-stretch gutter-b" v-if="!loading && !campaigns.length">
      <div class="card-body px-15 py-15 ">
        <router-link to="/build/new" class="btn btn-success font-weight-bolder font-size-lg d-block mx-auto" style="width: max-content;">
          <span class="svg-icon svg-icon-md svg-icon-white">
            <v-icon small>add_circle</v-icon>
          </span>
          Create your first campaign.
        </router-link>
      </div>
    </div>
    <div class="card card-custom card-stretch gutter-b" v-if="!loading && campaigns.length">
      <!--begin::Header-->
      <div class="card-header border-0 py-5 align-items-center">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark-75">
            Campaign
          </span>
        </h3>
        <div class="quick-search-wrapper d-flex flex-wrap align-items-center">
          <div
            class="quick-search quick-search-inline"
          >
            <!--begin::Form-->
            <form class="quick-search-form">
              <div class="input-group rounded bg-light">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <span class="svg-icon svg-icon-lg">
                      <inline-svg src="/media/svg/icons/General/Search.svg" />
                    </span>
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control h-40px"
                  placeholder="Search..."
                  v-model="query"
                  @input="filterCampaigns"
                  style="font-size:17px;"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i
                      class="quick-search-close ki ki-close icon-sm text-muted"
                    ></i>
                  </span>
                </div>
              </div>
            </form>
          </div>
          <div class="card-toolbar">
            <router-link to="/build/new" class="btn btn-success font-weight-bolder font-size-sm" style="padding-top: 9px;">
              <span class="svg-icon svg-icon-md svg-icon-white">
                <v-icon small>add_circle</v-icon>
              </span>
              New Campaign
            </router-link>
          </div>
        </div>
      </div>
      <v-dialog
        v-model="campaignPreviewDialog"
        max-width="800px"
      >
        <v-card style="border-radius:20px">
          <v-toolbar style="box-shadow:none; -webkit-box-shadow:none">
            <v-btn
              icon
              @click="closeCampaignPreview"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title><b>Campaign #{{campaignPreviewData && campaignPreviewData.id}}</b></v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text color="#000">
            <DashboardPreviewContent :campaign="campaignPreviewData" v-if="campaignPreviewData" />
          </v-card-text>
        </v-card>
      </v-dialog>
      <div class="card-body py-0">
        <!--begin::Table-->
        <div class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center"
            id="kt_advance_table_widget_1"
          >
            <thead class="sticky">
              <tr class="text-left">
                <th class="pr-0 sorted" style="min-width: 400px" @click="sort('name')">
                  <span>campaign</span>
                  <v-icon small v-if="sortBy === 'name'">{{sortIcon}}</v-icon>
                </th>
                <th style="min-width: 130px">url</th>
                <th>Mode</th>
                <!--<th>cloaked</th>
                <th>click</th>-->
                <th class="sorted pl-7" style="min-width: 50px" @click="sort('status')">
                  <span>status</span>
                  <v-icon small v-if="sortBy === 'status'">{{sortIcon}}</v-icon>
                </th>
                <th class="pr-3 text-right" style="min-width: 150px">action</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in campaigns">
                <tr v-bind:key="i" class="campaign-row" v-show="!item.hide">
                  <td class="pr-0">
                    <!--<span
                      class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                      @click="startEditName(item.id)"
                      v-if="!editNameForCampaign(item.id)"
                    >-->
                    <span
                      class="text-dark-50 font-weight-bolder text-hover-primary font-size-lg"
                    >
                      {{ item.name }}
                    <!--<v-text-field
                      v-model="item.name"
                      color="dark" dense outlined style="max-width: 80%"
                      v-if="editNameForCampaign(item.id)"
                      hide-details
                      autofocus
                      @blur="finishEditName(item.id, item.name, i)"
                    ></v-text-field>-->


                      <v-btn icon class="super-small text-muted font-weight-bold" style="margin-left:5px" @click="showCampaignPreview(item)">
                      <v-icon small>info</v-icon>
                      </v-btn>
                    </span>
                  </td>
                  <td>
                    <span
                      class="text-dark-50 font-weight-bolder campaign-url"
                      style="width: max-content;"
                    >
                      {{ getItemUrl(item) }}
                    </span>
                    <v-btn icon class="super-small text-muted font-weight-bold" style="margin-left:5px" v-if="getItemUrl(item).length" v-clipboard:copy="getItemUrl(item)">
                      <v-icon small>content_copy</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-chip x-small class="mx-1" v-if="item.mode" :color="getModeColor(item.mode)" text-color="white" style="text-transform: uppercase;">{{item.mode}}</v-chip>
                  </td>
                <!--  <td>{{item.click_filtered || 0}}</td>
                  <td>{{item.click || 0}}</td>-->
                  <td>
                    <v-select
                        v-model="item.status"
                        :items="status"
                        dense
                        outlined
                        append-icon=""
                        item-value="value"
                        :menu-props="{ top: false, offsetY: true }"
                        @change="changeStatus(item.id, item.status)"
                        hide-details
                        item-text="text"
                        style="max-width: 150px; color:white; height:32px; margin:-6px 0 0 0"
                      >
                        <template v-slot:item="{ item }">
                          <v-chip
                            small
                            label
                            :color="item.color || 'green'"
                          >
                            <v-icon small left>{{item.icon}}</v-icon>
                            {{item.text}}
                          </v-chip>
                        </template>
                        <template v-slot:selection="{ item }">
                          <v-chip
                            small
                            label
                            :color="item.color || 'green'"
                            style="width:86px; margin:auto"

                          ><span style="margin:auto">
                            <v-icon small left>{{item.icon}}</v-icon>
                            {{item.text}}

                          </span>
                          </v-chip>

                        </template>
                      </v-select>
                  </td>
                  <td class="pr-0 text-right" style="white-space: nowrap;">
                    <!--<button
                      @click="showCampaignPreview(item)"
                      class="btn btn-icon btn-light btn-hover-primary btn-xs mr-3"
                      title="Campaign Preview"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <v-icon>visibility</v-icon>
                      </span>
                    </button>
                  -->
                    <v-menu
                      absolute
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <a
                          class="btn btn-icon btn-light btn-hover-primary btn-xs mr-3"
                          v-on="on"
                          v-bind="attrs"
                          title="Action"
                        >
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <v-icon>settings</v-icon>
                          </span>
                        </a>
                      </template>
                      <div class="navi navi-hover min-w-md-250px">
                        <b-dropdown-text tag="div" class="navi-header pb-1">
                          <span class="text-primary text-uppercase font-weight-bold">
                            Available actions:
                          </span>
                        </b-dropdown-text>

                        <b-dropdown-text tag="div" class="navi-item">
                          <a :href="`${item.url}?c=${item.id}`" :disabled="!item.url" target="_blank" class="navi-link">
                            <span class="navi-icon">
                              <v-icon>launch</v-icon>
                            </span>
                            <span class="navi-text">
                              Open URL
                            </span>
                          </a>
                        </b-dropdown-text>

                        <!-- <b-dropdown-text tag="div" class="navi-item">
                          <router-link :to="'/build/' + item.id" class="navi-link">
                            <span class="navi-icon">
                              <i class="flaticon2-graph-1"></i>
                            </span>
                            <span class="navi-text">
                              Edit settings
                            </span>
                          </router-link>
                        </b-dropdown-text> -->

                        <b-dropdown-text tag="div" class="navi-item">
                          <router-link :to="'/stats/campaign/' + item.id" class="navi-link">
                            <span class="navi-icon">
                              <v-icon>bar_chart</v-icon>
                            </span>
                            <span class="navi-text">
                              Click Report
                            </span>
                          </router-link>
                        </b-dropdown-text>

                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            :disabled="!item.url"
                            :href="`${item.url}?c=${item.id}&${item.user.secretkey}=bypass`" target="_blank"
                            class="navi-link"
                          >
                            <span class="navi-icon">
                              <v-icon>lock_open</v-icon>
                            </span>
                            <span class="navi-text">
                              Bypass Cloaker
                            </span>
                          </a>
                        </b-dropdown-text>

                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            :disabled="!item.url"
                            :href="`${item.url}?c=${item.id}&${item.user.secretkey}=debug`" target="_blank"
                            class="navi-link"
                          >
                            <span class="navi-icon">
                              <v-icon>downloading</v-icon>
                            </span>
                            <span class="navi-text">
                              Test Installation
                            </span>
                          </a>
                        </b-dropdown-text>

                        <b-dropdown-text tag="div" class="navi-item">
                          <a href="#" class="navi-link" @click="duplicateCampaign(item.id)">
                            <span class="navi-icon">
                              <v-icon>file_copy</v-icon>
                            </span>
                            <span class="navi-text">
                              Duplicate
                            </span>
                          </a>
                        </b-dropdown-text>

                        <b-dropdown-text tag="div" class="navi-item">
                          <a href="#" class="navi-link" @click="removeCampaign(item.id)">
                            <span class="navi-icon">
                              <v-icon color="red">delete</v-icon>
                            </span>
                            <span class="navi-text red--text">
                              Delete
                            </span>
                          </a>
                        </b-dropdown-text>
                      </div>
                    </v-menu>
                    <router-link
                      :to="'/build/' + item.id"
                      class="btn btn-icon btn-light btn-hover-primary btn-xs mr-3"
                      title="Edit Settings"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <v-icon>edit</v-icon>
                      </span>
                    </router-link>
                    <!-- <router-link
                      :to="'/stats/campaign/' + item.id"
                      class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                      title="Stats"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <v-icon>bar_chart</v-icon>
                      </span>
                    </router-link> -->

                    <button
                      @click="openStatIframe(item.id)"
                      class="btn btn-icon btn-light btn-hover-primary btn-xs mr-3"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <v-icon>bar_chart</v-icon>
                      </span>
                    </button>

                    <!--<button
                      v-clipboard:copy="item.url + `?c=${item.id}`"
                      class="btn btn-icon btn-light btn-hover-primary btn-xs mr-3"
                      title="Copy URL"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <v-icon>content_copy</v-icon>
                      </span>
                    </button>-->
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { GET_CAMPAIGNS } from "@/core/services/store/campaign.module";

import DashboardPreviewContent from "@/view/components/Dashboard/DashboardPreviewContent";

import { mapState } from 'vuex'
import Swal from "sweetalert2";

export default {
  name: "DashboardTable",
  props: {
    openStatIframe: Function,
    loading: Boolean
  },
  components: {
    DashboardPreviewContent
  },
  data: () => ({
    status: [
      { text: 'Allow all', value: 'allow_all', color: '#ffeab6', icon: 'lock_open' },
      { text: 'Active', value: 'active', color: '#d1f7c4', icon: 'gpp_good' },
      { text: 'Block All', value: 'block_all', color: '#ffdce5', icon: 'block' }
    ],
    statuses: ['Block All', 'Active', 'Allow all'],
    campaignPreviewDialog: false,
    campaignPreviewData: null,
    sortBy: null,
    sortDirection: 'ASC',
    query: '',
    editNameIds: []
  }),
  computed: {
    ...mapState({
      campaigns: state => state.campaign.list
    }),
    sortIcon() {
      return this.sortDirection === 'ASC' ? 'south' : 'north'
    }
  },
  created() {
    this.$store.dispatch(GET_CAMPAIGNS, {
      newRequest: true,
      query: this.query,
      params: { ...this.getSortParams() }
    }).then(() => {
      this.$emit('set-loading', false);
    });
  },
  methods: {
    getItemUrl(item) {
      return item.url + (item.mode === 'dns' ? `?c=${item.id}` : '')
    },
    async changeStatus(id, status) {
      const result = await ApiService.put(`campaign/${id}/status`, { status })
        .then(res => res.data)
        .catch(err => {})
    },
    async removeCampaign(id) {
      const result = await ApiService.delete(`campaign/${id}`)
        .then(res => res.data)
        .catch(err => {})


      if (result && result.success) {
        Swal.fire({
          title: "",
          text: "Campaign was successfully deleted!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: true
        });

        this.$emit('set-loading', true);

        this.$store.dispatch(GET_CAMPAIGNS, {
          newRequest: true,
          query: this.query,
          params: { ...this.getSortParams() }
        }).then(() => {
          this.$emit('set-loading', false);
        });
      }
    },

    showCampaignPreview(data) {
      this.campaignPreviewDialog = true;
      this.campaignPreviewData = data;
    },

    closeCampaignPreview() {
      this.campaignPreviewDialog = false;
      this.$nextTick(() => {
        this.campaignPreviewData = null;
      })
    },

    startEditName(id) {
      this.editNameIds.push(id);
    },

    async finishEditName(id, name, index) {
      this.editNameIds = this.editNameIds.filter(it => it !== id);

      if (!name) {
        name = `Campaign ${id}`;

        this.$set(this.campaigns[index], 'name', name);
      }

      await ApiService.put(`campaign/${id}/name`, { name })
        .then(res => res.data)
        .catch(err => {})
    },

    editNameForCampaign(id) {
      return this.editNameIds.includes(id);
    },

    getSortParams() {
      if (!this.sortBy) return {};

      return {
        sortBy: this.sortBy,
        sortDirection: this.sortDirection
      }
    },

    async sort(field) {
      if (this.sortBy !== field) {
        this.sortBy = field;
        this.sortDirection = 'ASC'
      } else {
        this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
      }

      this.$emit('set-loading', true);

      this.$store.dispatch(GET_CAMPAIGNS, {
        newRequest: true,
        query: this.query,
        params: { ...this.getSortParams() }
      }).then(() => {
        this.$emit('set-loading', false);
      });
    },

    filterCampaigns() {
      this.$store.dispatch(GET_CAMPAIGNS, {
        newRequest: false,
        query: this.query,
        params: { ...this.getSortParams() }
      });
    },

    async duplicateCampaign(id) {
      const result = await ApiService.post(`campaign/${id}/duplicate`)
        .then(res => res.data)
        .catch(err => {})

      if (result && result.success && result.id) {
        Swal.fire({
          title: "",
          text: "Campaign was successfully duplicated!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: true
        });

        this.$router.push(`/build/${result.id}`)
        // this.$store.dispatch(GET_CAMPAIGNS);
      }
    },
    getStatusColorByValue(value) {
      const assoc = {
        0: '#F64E60',
        1: 'warning',
        2: 'success'
      };

      return assoc[value];
    },
    getModeColor(mode) {
       const assoc = {
        'dns': '#e63ae9',
        'js': 'warning',
        'api': 'success'
      };

      return assoc[mode];
    }
  }
};
</script>

<style scoped>

.btn.btn-icon.btn-xs {
  height: 28px;
  width: 28px;
}

.btn.btn-xs i {
    font-size: 1.3rem;
}

.v-application .primary--text {
  color: white !important;
  caret-color: white !important;
  border : none;
}

.v-menu__content {
  background: #fff;
}

.preview-img {
  cursor: pointer;
}

.preview-img:hover {
  outline: auto;
}

.preview-img {
  max-width: 100px;
}

.campaign-url {
  max-width: 350px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*
.campaign-row {
  border-bottom: 1px solid #cacaca;
  transition: 0.2s;
}

.campaign-row:hover {
  background: #e9e9e9;
} */

/* .campaign-row:last-child {
  border-bottom: 0;
} */

thead.sticky {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
}

.quick-search-wrapper {
  margin-left: auto;
}

.quick-search-inline {
  margin-right: 20px;
}

.campaign-row .v-select div[role="button"]::before {
  content: none;
}

.campaign-row .v-select .v-input__append-inner {
  display: none;
}

.v-input__control{
  height : 10px;
}

::v-deep .campaign-row .v-select fieldset {
  border: 0 !important;
}

.campaign-row td {
  padding: 0.2rem 0.75rem !important;
}

.table.table-head-bg thead tr:last-child, .table.table-head-bg thead th:last-child {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.table.table-head-bg thead tr:first-child, .table.table-head-bg thead th:first-child {
  border-bottom-left-radius: 0 !important;
}

.table-responsive {
  max-height: 700px;
}

th.sorted {
  cursor: pointer;
  transition: .7s;
}

.table.table-head-custom thead th.sorted:hover {
  color: #000 !important;
}

.table-responsive::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.table-responsive::-webkit-scrollbar-track {
  background: #e2e2e2;
  border-radius: 20px;
}
.table-responsive::-webkit-scrollbar-thumb {
  background-color: #8b8b8b;
  border-radius: 20px;
  border: 0px solid #e2e2e2;
}

.theme--light.v-card > .v-card__text {
  color: #000 !important;
}

.v-btn--icon.super-small {
  height: 22px !important;
  width: 22px !important;
}

::v-deep .status-slider.v-input__slider .v-slider__tick-label {
  font-size: 13px !important;
}
</style>
