<template>
<div>



  <div class="frame">
    <div class="frame-inner">
      <iframe
        :src="url"
        frameborder="0"
        border="0"
        width="100%"
        allowTransparency
        scrolling="yes"
        titled="false"
        @load="load"
        v-show="loaded"
        style="background: transparent"
      ></iframe>
    </div>
  </div>


</div>
</template>

<script>
export default {
  name: "metabase",

  components: {

  },


  data() {
    return {
      loaded: false,
    };
  },
  props: {
    url: { default: null }
  },

  methods: {

    load() {
    	this.loaded = true;
    }

  }

};
</script>

<style scoped>
.frame {
  margin: -0px 0px 0px 0px; /* -30 */
  overflow: hidden;
  height: 30vh;
  border-radius: 10px 0 0 10px;

}

.frame-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 30vh;
  margin: 0 0 0 0;
}

.frame-inner iframe {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0px 0 0 0;
  width: 100%;
  height: 30vh;
}




</style>
