<template>
  <div class="mt-4 font-size-sm">
    <div class="border-bottom mb-5 pb-5">
      <div class="font-weight-bold mb-3">
        Campaign Details:
      </div>
      <div class="line-height-md">
        Campaign name: {{campaign.name}}
        <br />
        <span class="d-flex align-items-center">
          Your Campaign URL is : {{completeUrl}}
        </span>
      </div>
    </div>
    <div class="border-bottom mb-5 pb-5">
      <div class="font-weight-bold mb-3">
        Filter Settings:
      </div>
      <div class="line-height-md">
        <span>
          Devices {{additional.deviceWhitelist ? 'allowed': 'disallowed'}}: {{additional.device.length ? additional.device.join(', ') : 'all'}}<br>
        </span>
        <span>
          Countries {{additional.countryWhitelist ? 'allowed': 'disallowed'}}: {{additional.country.length ? additional.country.join(', ') : 'all'}}<br>
        </span>

        Filter score: {{campaign.filter.score}}

        <div class="dayparting mt-3">
          <div class="dayparing-title mb-2">Traffic allowed:</div>
          <div class="dayparting-body mb-2" v-html="daypartingText"></div>
          <div class="dayparing-timezone mb-2">Timezone selected: {{campaign.filter.schedule && campaign.filter.schedule.timezone}}</div>
        </div>
      </div>
    </div>
    <div class="border-bottom mb-5 pb-5">
      <div class="font-weight-bold mb-3">
        Safe Page:
      </div>
      <div class="line-height-md">
        <div v-html="safeInfo"></div>
      </div>
    </div>

    <div class="border-bottom mb-5 pb-5">
      <div class="font-weight-bold mb-3">
        Money Page:
      </div>
      <div class="line-height-md">
        <div v-html="moneyInfo"></div>
      </div>
    </div>

    <div class="">
      <div class="font-weight-bold mb-3">
        Installation:
      </div>
      <div class="line-height-md">
        Mode {{campaign.mode}}
        <br>
        <div v-html="installInfo"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    campaign: Object
  },
  computed: {
    ...mapGetters(["userdata", "appConfig"]),
    completeUrl() {
      return this.campaign.url + `?c=${this.campaign.id}`
    },
    additional() {
      const result = {};
      const filterKeys = [
        'country', 'device', 'os', 'region', 'city',
        'browser', 'language', 'ip', 'url', 'referer'
      ]

      filterKeys.forEach(key => {
        result[`${key}Whitelist`] = this.campaign.filter[`${key}_allowed`] !== "";

        result[key] = result[`${key}Whitelist`] ? this.campaign.filter[`${key}_allowed`] : this.campaign.filter[`${key}_disallowed`];
      })

      return result;
    },
    daypartingText() {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

      return days.map((day) => {
        const schedule = this.campaign.filter.schedule[day] || [];
        
        const hasOrder = schedule.length && schedule.every((it, index, arr) => index !== arr.length - 1 ? it + 1 === arr[index + 1] : true);
        let text;

        if (hasOrder) {
          text = schedule.length && schedule.length >= 2 ? `From ${schedule[0]} - To ${schedule[schedule.length - 1]}` : schedule[0];
        } else {
          text = schedule.join(', ')
        }
    
        return `<div class="ml-3">${day}: ${text}</div>`
      }).join('')
    },
    safeInfo() {
      if (this.campaign.safe.mode == "stay"){
        return `Bot will stay on the same page ${this.campaign.safe.name} <a href="${this.campaign.url}" target="_blank">${this.campaign.url}</a>`
      } else if (this.campaign.safe.mode == "reverse proxy"){
        return `Bot will see content of this page ${this.campaign.safe.name} <a href="${this.campaign.safe.url}" target="_blank">${this.campaign.safe.url}</a>  without redirect`
      } else if (this.campaign.safe.mode == "redirect"){
        return `Bot are redirected to this page ${this.campaign.safe.name} <a href="${this.campaign.safe.url}" target="_blank"> ${this.campaign.safe.url}</a>`
      } else {
        return ""
      }
    },
    moneyInfo() {
      const money = this.campaign.money && this.campaign.money[0] || {};

      if ( money.mode == "iframe"){
        return `Real visitors will see content of money page ${money.name} <a href="${money.url}" target="_blank"> ${money.url}</a> in an iframe`
      } else if ( money.mode == "reverse proxy"){
        return `Real visitors will see content of money page ${money.name} <a href="${money.url}" target="_blank"> ${money.url}</a> without redirect ( check if layout issue )`
      } else if ( money.mode == "redirect"){
        return `Real visitors are to be redirected to money page ${money.name} <a href="${money.url}" target="_blank">${money.url}</a>`
      } else if ( money.name != ""){
        return `Real visitors will see ${money.name}`
      } else {
        return ""
      }
    },
    installInfo() {
      if (this.campaign.mode == "JS") {
        return `Place the JS code in the head tag of your page.<br> <code></code><br><a href="https://docs.cloakone.com/cloaker/installation/js" target="_blank">Click here for more instructions</a>`
      } else if (this.campaign.mode == "DNS"){
        return `Install the cloaker on your domain with cloudflare. <a href="https://docs.cloakone.com/cloaker/installation/dns" target="_blank">Click here for more instructions</a>`
      } else {
        return ""
      }
    },
    debugInfo() {
      if (this.campaign.mode == "JS") {
        return `Once you place the js code in your page, you can use your secretkey to debug`
      } else if (this.campaign.mode == "DNS"){
        return `Once you installed the cloaker on your cloudflare, you can use your secretkey to debug`
      } else {
        return ""
      }
    }
  }
}
</script>
